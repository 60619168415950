import React from 'react';
import ReactDOM from 'react-dom';

import 'normalize.css';
import './index.scss';

import './extensions/string-extensions';

import App from './views/App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import setupHotjar from './telemetry/hotjar';

setupHotjar();

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain="payloadalert.eu.auth0.com"
            clientId="zFUB3Tzd8gjvkPjak839bB29Hu4UEd10"
            redirectUri={window.location.origin}
        >
            <App/>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
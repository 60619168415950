import React from 'react';

import '../styles/Alert.scss';

import HttpVerbs from '../enums/HttpVerbs';
import { useDeleteAlert } from '../services/delete-alert-service';
import Button from './Button';
import { formatDistanceToNow } from 'date-fns';

interface IAlertProps {
    id: string;
    url: string;
    verb: HttpVerbs;
    monitorPayloadValues: boolean;
    lastChecked: Date | undefined;
    lastUpdated: Date;
    onAlertRemoved: (id: string) => void;
}

const Alert: React.FC<IAlertProps> = ({id, url, verb, monitorPayloadValues, onAlertRemoved, lastChecked, lastUpdated}) => {
    const deleteAlert = useDeleteAlert();
    
    return (
        <article className="alert">
            <p>GET</p>
            <p>{url}</p>
            {lastChecked && <p>Last checked {formatDistanceToNow(lastChecked, {
                addSuffix: true,
            })}</p>}
            <p>Last alerted {formatDistanceToNow(lastUpdated, {
                addSuffix: true,
            })}</p>
            <p><strong>Monitoring {monitorPayloadValues ? "structure and values" : "structure only"}</strong></p>
            <Button text='Delete' onClick={() => {
                deleteAlert(id).then(x => {
                    onAlertRemoved(id);
                });
            }} />
        </article>
    );
}

export default Alert;
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from "react-router-dom";

import '../styles/Home.scss';

import Card from '../components/Card';
import User from '../components/User';
import Form from '../components/Form';
import LoadingSpinner from '../components/LoadingSpinner';
import Tiles from '../components/Tiles';
import InfoIcon from '../assets/info-icon.svg'
import Button from '../components/Button';

const HomeView: React.FC = (): JSX.Element => {
    const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

    return (
        <Card className="home">
            {!isAuthenticated ? renderUnauthenticatedContent(isLoading, loginWithRedirect) : renderAuthenticatedContent(() => logout({returnTo: window.location.origin}))}
        </Card>
    )
}

function renderUnauthenticatedContent(isLoading: boolean, loginWithRedirect: () => void): JSX.Element {
    return (
        <div className="home__unauthenticated">
            <div className="home__information">
                <h2>Welcome</h2>
                <p>Payload Alert is a free-to-use API response monitoring service.</p>
                <p>Set up an alert to monitor JSON response payloads and get notified when they change.</p>

                {!isLoading && <Tiles
                    tiles={[
                        { title: 'Intelligence', description: 'An intelligent solution to monitoring third party services.', icon: InfoIcon },
                        { title: 'Operational Integrity', description: 'Optimise reliability of your dependent services.', icon: InfoIcon },
                        { title: 'Fast Response', description: 'Get notified quickly via email.', icon: InfoIcon }
                    ]}
                />}
            </div>

            <div className="home__log-in-prompt">
                {isLoading ?
                    <LoadingSpinner width={"5rem"} height={"5rem"}/>
                    :
                    <>
                        <strong>Ready to get started?</strong>
                        <Button onClick={() => loginWithRedirect()} text="Log In"/>
                    </>
                }
            </div>
        </div>
    );
}

function renderAuthenticatedContent(logout: () => void): JSX.Element {
    return (
        <div className="home__authenticated">
            <User/>

            <nav className="home__pages">
                <Link to="/my-alerts">My Alerts</Link>
                <Link to="/" onClick={logout}>Logout</Link>
            </nav>

            <Form/>
        </div>
    );
}

export default HomeView;

import { APIM_URL } from '../utilities/constants';
import {
    del,
    get,
    post
} from '../utilities/http-utils';
import ICreateAlert from '../interfaces/create-alert';
import ICreateAlertResponse from '../interfaces/create-alert-response';
import IAlertResponse from '../interfaces/alert-response';

export async function postAlert(accessToken: string, createAlert: ICreateAlert): Promise<ICreateAlertResponse> {
    const apiURL = `${APIM_URL}alert/create`;
    return await post(apiURL, createAlert, accessToken);
}

export async function deleteAlert(accessToken: string, id: string): Promise<void> {
    const apiURL = `${APIM_URL}alert/${id}`;
    return await del(apiURL, accessToken);
}

export async function getAlerts(accessToken: string): Promise<ReadonlyArray<IAlertResponse>> {
    const apiURL = `${APIM_URL}alert/get-all`;
    return await get(apiURL, accessToken);
}
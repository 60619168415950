import React from 'react';

import '../styles/User.scss';

import { useAuth0 } from '@auth0/auth0-react';

const User: React.FC = (): JSX.Element | null => {
    const { user, isAuthenticated } = useAuth0();

    if (!isAuthenticated || user === undefined) {
        return null;
    }

    return (
        <div className="user">
            <div className="user__container">
                <img src={user.picture} alt={user.name}/>
                <p>{user.email}</p>
            </div>
        </div>
    );
}

export default User;
import React from 'react';
import classNames from 'classnames';

import '../styles/Toast.scss';

interface IToastProps {
    success: boolean;
    message: JSX.Element | string;
}

const Toast: React.FC<IToastProps> = ({ success, message }) => {
    const className = classNames('toast', {
        'toast--successful': success
    });

    return (
        <div className={className} role={!success ? 'alert' : undefined}>
            {message}
        </div>
    );
}

export default Toast;
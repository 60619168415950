import React from 'react';
import classnames from 'classnames';

import '../styles/Button.scss';

import LoadingSpinner from './LoadingSpinner';

type ButtonType = 'button' | 'submit';

interface IButtonProps {
    text: string;
    onClick?: () => void;
    type?: ButtonType;
    className?: string;
    inProgress?: boolean;
    disabled?: boolean;
    showText?: boolean;
}

export const Button: React.FC<IButtonProps> = ({
    text,
    onClick,
    type = 'button',
    className,
    inProgress,
    disabled,
    showText = true,
}): JSX.Element => {
    const rootClassname: string = classnames('button', className, {
        'button--disabled': disabled === true || inProgress === true,
        'button--in-progress': inProgress === true,
    });

    return (
        <button
            name={text}
            className={rootClassname}
            type={type as ButtonType}
            onClick={onClick}
            disabled={inProgress === true || disabled === true}
        >
            {showText && <span className="button__text">{text}</span>}

            {inProgress === true && (
                <LoadingSpinner width={'1em'} height={'1em'} type="TailSpin" />
            )}
        </button>
    );
};

export default Button;
import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import '../styles/Card.scss';

interface ICardProps {
    className?: string;
}

const Card: React.FC<PropsWithChildren<ICardProps>> = ({ children, className }) => {
    const rootClassName = classNames("card", className);
    return (
        <div className={rootClassName}>
            {children}
        </div>
    );
}

export default Card;
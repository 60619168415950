type ReturnType = any | undefined;
type Method = 'PUT' | 'POST' | 'DELETE' | 'GET';
type DataType = {} | undefined;

export async function post(
    url: string,
    data: DataType,
    bearerToken?: string
): Promise<ReturnType> {
    return await performFetch(url, 'POST', data, bearerToken);
}

export async function get(
    url: string,
    bearerToken?: string
): Promise<ReturnType> {
    return await performFetch(url, 'GET', undefined, bearerToken);
}

export async function del(
    url: string,
    bearerToken?: string
): Promise<ReturnType> {
    return await performFetch(url, 'DELETE', undefined, bearerToken);
}

async function performFetch(
    url: string,
    method: Method,
    data?: DataType,
    bearerToken?: string
): Promise<ReturnType> {
    const response: Response = await fetch(url, {
        method: method,
        body: data !== undefined ? JSON.stringify(data) : undefined,
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': bearerToken != undefined ? bearerToken : '',
        },
    });

    const textResponse = await response.text();
    const deserialisedResponse: ReturnType = deserialise(textResponse);

    if (!response.ok) {
        throw new ResponseError(
            `An error occurred during the ${method} to ${url} with body ${
                data !== null && data !== undefined ? JSON.stringify(data) : ''
            }.}`,
            response.status,
            deserialisedResponse
        );
    }

    return deserialisedResponse;
}

function deserialise(textResponse: string): {} | undefined {
    try {
        return textResponse !== undefined
            ? JSON.parse(textResponse)
            : undefined;
    }
    catch (e) {
        console.warn(`Unable to deserialise response ${textResponse}.`);
        return undefined;
    }
}

export class ResponseError extends Error {
    private responseCode: number;
    private responseObject: {} | undefined;

    public constructor(
        message: string,
        responseCode: number,
        responseObject?: {}
    ) {
        super(message);

        this.responseCode = responseCode;
        this.responseObject = responseObject;
    }

    public get ResponseCode(): number {
        return this.responseCode;
    }

    public get ResponseObject(): {} | undefined {
        return this.responseObject;
    }
}
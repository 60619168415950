import React from 'react';

import '../styles/Form.scss';

import {
    ICreateAlertReturnParams,
    useCreateAlert
} from '../services/create-alert-service';
import Toast from './Toast';
import Button from './Button';
import Field from './Field';

const Form: React.FC = (): JSX.Element => {
    const createAlert = useCreateAlert();

    const [url, setUrl] = React.useState<string | undefined>(undefined);
    const [authToken, setAuthToken] = React.useState<string | undefined>(undefined);
    const [interval, setInterval] = React.useState<number | undefined>(60);
    const [monitorPayloadValues, setMonitorPayloadValues] = React.useState<boolean>(false);
    const [response, setResponse] = React.useState<ICreateAlertReturnParams | undefined>(undefined);
 
    const [inProgress, setInProgress] = React.useState<boolean>(false);

    return (
        <form
            className="form"
            onSubmit={e => {
                if (!inProgress) {
                    setInProgress(true);
                    setResponse(undefined);

                    createAlert({
                        url: url ?? "",
                        monitorPayloadValues,
                        authToken,
                        intervalMilliseconds: interval ? (interval * 60) * 1000 : 60000,
                    })
                        .then(x => setResponse(x))
                        .finally(() => setInProgress(false));
                }

                e.preventDefault();
                e.stopPropagation();
            }}>
            <Field 
                id='url'
                value={undefined}
                label='URL (GET verb only)'
                required={true}
                pattern="\S(.*\S)?"
                type="text"
                onValueChanged={value => setUrl(value)}
                title='URL field which must contain a valid value'
            />

            <Field
                id='authorization'
                value={undefined}
                label='Authorization Token'
                pattern="\S(.*\S)?"
                type="text"
                onValueChanged={value => setAuthToken(value)}
                title='Authorization token field which must contain a valid value'
            />
            
            <span className="form__field">
                <label htmlFor="monitorValues">Monitor Payload Values</label>
                <input id="monitorValues" type="checkbox" onChange={e => setMonitorPayloadValues(e.target.checked)}/>
            </span>
            
            <Field
                id='interval'
                value={interval?.toString()}
                label='Check Frequency (in minutes)'
                required={true}
                type="number"
                onValueChanged={value => setInterval(parseInt(value))}
                min={1}
                max={44640}
                title='Check frequency field which must contain a valid value'
            />
            
            <Button inProgress={inProgress} type="submit" text="Start monitoring"/>

            {response != undefined &&
                <Toast
                    success={response.success}
                    message={
                        <span>
                            {response.success ?
                                <strong>You are now monitoring:</strong> :
                                <strong>There appears to be an error:</strong>
                            }
                            <p>{response.response}</p>
                        </span>
                    }
                />
            }
        </form>
    );
}

export default Form;
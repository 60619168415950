import { postAlert } from "../data-services/alert-data-service";
import { useAuth0 } from '@auth0/auth0-react';
import ICreateAlert from '../interfaces/create-alert';
import { ResponseError } from '../utilities/http-utils';
import ICreateAlertResponse from '../interfaces/create-alert-response';
import ApplicationErrorCode from '../enums/ApplicationErrorCode';

interface ICreateAlertParams {
    readonly url: string;
    readonly monitorPayloadValues: boolean;
    readonly authToken: string | undefined;
    readonly intervalMilliseconds: number;
}

export interface ICreateAlertReturnParams {
    success: boolean,
    response: string
}

export function useCreateAlert(): (params: ICreateAlertParams) => Promise<ICreateAlertReturnParams> {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    return async (params: ICreateAlertParams): Promise<ICreateAlertReturnParams> => {
        try {
            if (!isAuthenticated) {
                throw new Error('This function cannot be used without first being logged in');
            }

            const alert: ICreateAlert = {
                url: params.url.trim(),
                verb: 0,
                headers: {},
                intervalMilliseconds: params.intervalMilliseconds,
                monitorPayloadValues: params.monitorPayloadValues
            }

            if (params.authToken != undefined && !params.authToken.trim().isEmpty()) {
                alert.headers['Authorization'] = params.authToken.trim();
            }

            const accessToken = await getAccessTokenSilently();
            const response = await postAlert(accessToken, alert);

            return {
                success: response.success,
                response: getResponseText(response.errorCode, response.response, response.urlStatusCode),
            };
        }
        catch (e) {
            console.log(e);

            // TODO: TypeGuard
            if (e instanceof ResponseError) {
                const assertedObj = e.ResponseObject as ICreateAlertResponse;
                return {
                    success: assertedObj.success,
                    response: getResponseText(assertedObj.errorCode, assertedObj.response, assertedObj.urlStatusCode),
                };
            }

            return {
                success: false,
                response: "General failure occurred"
            };
        }
    }
}

function getResponseText(
    errorCode: ApplicationErrorCode | null,
    serverResponse: string,
    urlStatusCode: number | null,
): string {
    if (errorCode === null) {
        return serverResponse;
    }

    switch (errorCode) {
        case ApplicationErrorCode.NoUser:
            return "Your user could not be found. Please try again."
        case ApplicationErrorCode.InvalidEmail:
            return "Your email doesn't appear to be correct. Please try again."
        case ApplicationErrorCode.IntervalNotInRange:
            return "Please choose an interval frequency between 60 and 2678400 seconds."
        case ApplicationErrorCode.InvalidUrl:
            return "The URL doesn't appear to be correct. Please try again."
        case ApplicationErrorCode.InvalidPayload:
            return "The URL returned an invalid payload. Please try again."
        case ApplicationErrorCode.UrlError:
            return `There was an issue contacting this URL. Please try again. ${urlStatusCode !== null ? `Status Code: ${urlStatusCode}` : ""}`
        case ApplicationErrorCode.MaxLimit:
            return "Unfortunately, you are only able to create a maximum of 10 alerts."
        case ApplicationErrorCode.Duplicate:
            return "You are already monitoring this URL."

        case ApplicationErrorCode.Unknown:
        case ApplicationErrorCode.General:
        default:
            return serverResponse;
    }
}
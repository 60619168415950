import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from "react-router-dom";

import '../styles/MyAlerts.scss';

import Card from '../components/Card';
import {
    IAlert,
    useGetAlerts
} from '../services/get-alerts-service';
import Alert from '../components/Alert';
import LoadingSpinner from '../components/LoadingSpinner';

const MyAlertsView: React.FC = (): JSX.Element => {
    const { isAuthenticated, isLoading } = useAuth0();
    const { alerts: alertData, isLoading: isLoadingAlerts } = useGetAlerts();
    const loading = isLoading || isLoadingAlerts;
    
    const [alerts, setAlerts] = React.useState<ReadonlyArray<IAlert> | undefined>(undefined);
    React.useEffect(() => {
        setAlerts(alertData);
    }, [alertData])
    
    const onAlertRemovedCallback = React.useCallback((id: string) => {
        if(alerts !== undefined) {
            setAlerts(alerts.filter(x => x.id !== id))  
        }
    }, [alerts])

    function renderContent(): ReadonlyArray<JSX.Element> | JSX.Element | null {
        if (loading) {
            return <LoadingSpinner width={"5rem"} height={"5rem"}/>
        }

        if (alerts === undefined) {
            // We haven't fetched them yet.
            return null;
        }

        if (alerts.length > 0) {
            return alerts.map(x =>
                <Alert
                    key={x.id}
                    id={x.id}
                    url={x.url}
                    verb={x.verb}
                    monitorPayloadValues={x.monitorPayloadValues}
                    lastChecked={x.lastChecked}
                    lastUpdated={x.lastUpdated}
                    onAlertRemoved={onAlertRemovedCallback}/>
            )
        }

        return <p>You do not appear to have any alerts.</p>
    }

    return (
        <Card className="my-alerts">
            <Link className="my-alerts__back" to="/">Go back</Link>

            <h2>My Alerts</h2>

            {renderContent()}
        </Card>
    )
}

export default MyAlertsView;
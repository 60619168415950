import React  from 'react';
import classNames from 'classnames';

import '../styles/Tiles.scss';

interface ITile {
    title: string;
    description: string;
    icon: string
}

interface ITileProps {
    className?: string;
    tiles: ReadonlyArray<ITile>;
}

const Tiles: React.FC<ITileProps> = ({ className, tiles }) => {
    const rootClassName = classNames("tiles", className);
    
    return (
        <div className={rootClassName}>
            {tiles.map(renderTile)}
        </div>
    );
}

function renderTile(tile: ITile): JSX.Element {
    return (
        <article className="tile__article">
            <img className="tile__icon" src={tile.icon} alt="" />
            <h3>{tile.title}</h3>
            <p>{tile.description}</p>
        </article>
    )
}

export default Tiles;
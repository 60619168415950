import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    deleteAlert,
} from '../data-services/alert-data-service';

export function useDeleteAlert(): (id: string) => Promise<void> {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    return async (id: string): Promise<void> => {
        try {
            if (!isAuthenticated) {
                throw new Error('This function cannot be used without first being logged in');
            }

            const accessToken = await getAccessTokenSilently();
            await deleteAlert(accessToken, id);
        }
        catch (e) {
            console.log(e);
        }
    }
}
import Loader from 'react-loader-spinner';
import React from 'react';
import classnames from 'classnames';

import '../styles/LoadingSpinner.scss';

type LoadingType = 'ThreeDots' | 'Circles' | 'Rings' | 'TailSpin';

interface ILoadingSpinnerProps {
    className?: string;
    width: string | number;
    height: string | number;
    type?: LoadingType;
}

const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
    width,
    height,
    type = 'TailSpin',
    className,
}) => {
    const rootClassname = classnames('loading-spinner', className);

    return (
        <div className={rootClassname}>
            <Loader type={type} color="#53adca" height={height} width={width} />
        </div>
    );
};

export default LoadingSpinner;
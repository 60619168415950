enum ApplicationErrorCode {
    Unknown,
    NoUser,
    InvalidEmail,
    IntervalNotInRange,
    InvalidUrl,
    InvalidPayload,
    UrlError,
    General,
    MaxLimit,
    Duplicate,
}

export default ApplicationErrorCode;
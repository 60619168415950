import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAlerts } from '../data-services/alert-data-service';
import HttpVerbs from '../enums/HttpVerbs';

export interface IAlert {
    readonly id: string;
    readonly payload: string;
    readonly url: string;
    readonly verb: HttpVerbs,
    readonly monitorPayloadValues: boolean;
    readonly lastChecked: Date | undefined;
    readonly lastUpdated: Date;
}

interface IGetAlertsReturnParams {
    readonly alerts: ReadonlyArray<IAlert> | undefined;
    readonly isLoading: boolean;
}

export function useGetAlerts(): IGetAlertsReturnParams {
    const [alerts, setAlerts] = React.useState<ReadonlyArray<IAlert> | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    React.useEffect(() => {
        if (isAuthenticated) {
            setIsLoading(true);

            getAccessTokenSilently()
                .then((accessToken) => {
                    getAlerts(accessToken)
                        .then(alerts => {
                            setAlerts(alerts.map(x => {
                                return {
                                    id: x.id,
                                    payload: x.payload,
                                    url: x.url,
                                    verb: x.verb,
                                    monitorPayloadValues: x.monitorPayloadValues,
                                    lastUpdated: new Date(x.lastUpdated),
                                    lastChecked: x.lastChecked ? new Date(x.lastChecked) : undefined,
                                }
                            }))
                        })
                        .catch(error => {
                            console.error("Failed to get alerts");
                        })
                        .finally(() => setIsLoading(false));
                })
                .catch((error) => {
                    console.error("Failed to get access token");
                    setIsLoading(false)
                })
        }
    }, [isAuthenticated, getAccessTokenSilently])


    return {
        alerts: alerts,
        isLoading: isLoading,
    };
}
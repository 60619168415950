import React from 'react';

import '../styles/Field.scss';

interface IFieldProps {
    id: string;
    type: string;
    title?: string;
    label: string;
    value: string | undefined;
    required?: boolean;
    pattern?: string;
    onValueChanged: (value: string) => void;
    min?: number;
    max?: number;
}

const Field: React.FC<IFieldProps> = ({id, type, title, label, value: initialValue, required, pattern, onValueChanged, min, max}): JSX.Element => {
    const [value, setValue] = React.useState<string | undefined>(initialValue);
    
    const onChangeCallback = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        setValue(value);
        if(value !== undefined) {
            onValueChanged(value);  
        }
    }
    
    return(
        <span className="field">
                <label htmlFor={id}>{`${label} ${required ? '*' : ''}`}</label>
                <input
                    required={required}
                    pattern={pattern}
                    title={title}
                    id={id}
                    type={type}
                    onChange={onChangeCallback}
                    min={min}
                    max={max}
                    value={value}
                />
            </span>
    )
}
export default Field;
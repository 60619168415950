import React from 'react';
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    Link,
} from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

import '../styles/App.scss';

import PrivacyPolicyView from './PrivacyPolicy';
import HomeView from './Home';
import MyAlertsView from './MyAlerts';
import { ReactComponent as Logo } from '../assets/bell-icon.svg';
import Button from '../components/Button';

const App: React.FC = (): JSX.Element => {
    const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();
    
    return (
        <div className="app">
            <BrowserRouter>
                <header  className="app__header" >
                    <Link className="app__link" to="/">
                        <Logo className="app__logo"/>
                        <h1>Payload Alert</h1>
                    </Link>

                    {!isAuthenticated && !isLoading && <Button onClick={() => loginWithRedirect()} text="Log In"/>}
                </header>

                {/* TODO: Private Route Functionality */}
                <main className="app__main">
                    <Routes>
                        <Route path="/" element={<HomeView/>}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicyView/>}/>
                        <Route path="/my-alerts" element={<MyAlertsView/>}/>
                        <Route
                            path="*"
                            element={<Navigate to="/" replace/>}
                        />
                    </Routes>
                </main>

                <footer className="app__footer">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </footer>
            </BrowserRouter>
        </div>
    )
}

export default App;
import React from 'react';
import { Link } from "react-router-dom";

import '../styles/PrivacyPolicy.scss';

import PrivacyPolicy from '../components/PrivacyPolicy';
import Card from '../components/Card';

const PrivacyPolicyView: React.FC = (): JSX.Element => {
    return (
        <Card className="privacy-policy">
            <Link className="privacy-policy__back" to="/">Go back</Link>

            <PrivacyPolicy/>
        </Card>
    )
}

export default PrivacyPolicyView;